import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { PageProps } from '@types'
import { TransitionMask } from '@components/organisms'
import { Grid, Margin } from '@components/atoms'
import { GridItem } from '@components/molecules'


const NotFoundPage = () => (
  <Main backgroundColor="#0A0A0A" >
    <Margin>
      <Grid>
        <Message>¯\_(ツ)_/¯</Message>
      </Grid>
    </Margin>
    <TransitionMask />
  </Main>
)


const Main = styled.main<{ backgroundColor: string }>`
  position: relative;
  z-index: 2;
  background-color: ${props => props.backgroundColor};
  color: white;
  min-height: calc(100vh - 11.5vw - 20.5vw);
`


const Message = styled.div`
  grid-column-end: span 6;
  color: white;
  text-align: center;
  font-size: 6em;
  font-family: 'NHaasGroteskDSW01-65Md', sans-serif;
  @media only screen and (min-width: 768px) {
    grid-column-end: span 12;
    font-size: 3em;
  }
`


export default NotFoundPage